import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import style from './style.module.css'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ButtonGroup
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BsEyeFill } from 'react-icons/bs'
import {
  usePostFollowupsMutation
} from '../../../../store/crm'

import Alert from '../../../alert'

import { RiHotelFill } from 'react-icons/ri'
import { ImCross } from 'react-icons/im'
import { PiAirplaneTiltFill } from 'react-icons/pi'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TimeLineShow from '../timeline/index'
import swal from 'sweetalert'

const EnquiryDataTab = React.memo(({ client }) => {
  let navigate = useNavigate();
  const [state, setState] = React.useState({
    left: false
  })
  const [followForm, setFollowForm] = useState({
    status:'',
    remarks:"",
  })

  const [addFollowUp] = usePostFollowupsMutation();

const handleSubmit = async()=>{
  let userId  = localStorage.getItem('id')

  let payload = {
    issueId:client._id,
    status:followForm.status,
    remarks:followForm.remarks,
    employId:userId
  }
  let response = await addFollowUp(payload)
 
  if(response?.data?.status==="success"){
    swal(
      'Submitted Successfully!',
      "status updated !!",
      'success'
    )
    setState(false)
  }
}



 

 
const toggleDrawer = (anchor, open) => event => {
  if (
    event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')
  ) {
    return
  }

  setState({ ...state, [anchor]: open })
}
 

  const list = anchor => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '100%' }}
      role='presentation'
    >
     
   

      <div className={style.headings}>
        <div className={style.insideHeading}>
          <p>Issue No. : </p>
          <p>{client.enquiryNo}</p>
        </div>

        <div className={style.insideHeading}>
          <p>Issue Status : </p>
          <p>{client.status}</p>
        </div>
      </div>


      <TimeLineShow issueId={client._id}/>


      <div className={style.custDetails}>
        <div className={style.custDetailsBtm}>
          <p className={style.title}>Issue Title : </p>
          <p className={style.val}>{client.issueTitle}</p>
        </div>
      </div>

      <div className={style.custDetails}>
        <div className={style.custDetailsVal}>
          <p className={style.title}>Issue Details : </p>
          <p className={style.val}>{client.issueDetails}</p>
        </div>
      </div>

      <Divider />

      {client?.handledBy === localStorage.getItem('user') || true ? (
        <>
        


          <Divider />

          <div className={style.custDetails}>
            <div className={style.custDetailsBtm}>
              <FormControl size='small' sx={{ width: '150px', mt: 2 }}>
                <InputLabel
                  size='small'
                  sx={{
                    width: '150px',

                    fontSize: '13px',
                    fontFamily: 'comfortaa'
                  }}
                  id='demo-simple-select-label'
                >
                  Status
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Status'
                  value={followForm.status}
                  onChange={e => {
                    setFollowForm({ ...followForm, status: e.target.value })
                  }}
                >
                  <MenuItem value='pending'>Pending</MenuItem>
                  <MenuItem value='assign'> assign</MenuItem>
                  <MenuItem value='working'> Working</MenuItem>
                  <MenuItem value='solved'> Solved</MenuItem>
                  <MenuItem value='canceled'> Cancel</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className={style.custDetailsBtm}>
              <TextField
            sx={{ width: '100%' }}
            placeholder='Remarks'
            multiline
            rows={3}
            InputProps={{
              style: {
                fontFamily: 'Comfortaa',
                width: '300px',

                fontSize: '12px'
              }
            }}
            value={followForm.remarks}
            onChange={e => {
              setFollowForm({ ...followForm, remarks: e.target.value })
            }}
          />
            </div>
          </div>

          {/* <div className={style.custDetails}>
        <div className={style.custDetailsBtm}>
          <FormControl size='small' sx={{ width: '150px', mt: 2 }}>
            <InputLabel
              size='small'
              sx={{
                width: '150px',

                fontSize: '13px',
                fontFamily: 'comfortaa'
              }}
              id='demo-simple-select-label'
            >
              Status
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Status'
              value={queryUpd.status}
              onChange={e => {
                setQueryUpd({ ...queryUpd, status: e.target.value })
              }}
            >
              <MenuItem value='pending'>Pending</MenuItem>
              <MenuItem value='quoted'> Quote</MenuItem>
              <MenuItem value='lost'> Lost</MenuItem>
              <MenuItem value='confirm'> Confirm</MenuItem>
              <MenuItem value='canceled'> Cancel</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={style.custDetailsBtm}>
        
        </div>
      </div> */}
          <Divider />
          <div className={style.custDetails}>
            <Button
              variant='outlined'
              color='error'
              onClick={() => setState({ left: false })}
            >
              Back
            </Button>
            {/* {isLoading ? (
          <Button variant='outlined' color='success' sx={{ width: '100px' }}>
            <CircularProgress size='1.5rem' color='success' />
          </Button>
        ) : (
          <Button
            variant='contained'
            color='success'
            onClick={handleClick}
            sx={{ width: '100px' }}
          >
            Submit
          </Button>
        )} */}

            <Button
              variant='contained'
              color='info'
              onClick={handleSubmit}
            >
              Change Status
            </Button>
          </div>
        </>
      ) : (
        <Button
          variant='outlined'
          color='error'
          sx={{ mt: 2, ml: 2 }}
          onClick={() => setState({ left: false })}
        >
          Back
        </Button>
      )}
    </Box>
  )

  return (
    <div>
      {['left'].map(anchor => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {' '}
            <BsEyeFill />{' '}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
})

const upd = time => {
  var dte = new Date(time).toLocaleString()
  // console.log(dte)
  return <>{dte}</>
}

const menuItems = Array.from({ length: 41 }, (_, index) => (
  <MenuItem key={index} value={index}>
    {index}
  </MenuItem>
))

export default EnquiryDataTab

// const createTable = (heading, val)=>{
//   console.log(val.split('.'))
//   var split = val.split('.').map((item)=>{  return `<span style="border:2px solid black;"> ${item}</span><br/>`})
//   console.log("sp",split)
//   var sp = "";
//  split.forEach((item)=> {
//     sp+= item;
//  })
//   return `
//   <div style="border:2px solid black;">
//   <table >
//   <tr>
//     <td style="color:red;">${heading}</td>
//   <td>
//   ${sp}
//   </td>
//   </tr>
//   </table>
//   </div>
//   `
// }
