const config = {
 
  basename: '',
  defaultPath: '/dashboard/default',
  fontFamily: `'roboto', sans-serif`,
  borderRadius: 12
};

export default config;




