import EnquiryTable from "../enquiryTable";
import { Card } from '@mui/material';

export default function MyEnquiry() {
  return (
    <div>
      <Card sx={{ mt: 2 }}>
        <EnquiryTable />
      </Card>
    </div>
  );
}
