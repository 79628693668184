import { lazy } from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from 'components/ui-component/Loadable';
import {Navigate} from 'react-router-dom'
import MyEnquiry from 'components/enquiry/MyEnquiry';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/Default')));



const AllQuery = Loadable(lazy(() => import('../components/enquiry')));
const QueryById = Loadable(lazy(() => import('../pages/query')));
const AddQuery = Loadable(lazy(() => import('../components/enquiry/enquiryForm/containHeader')));
const QueryByStatus = Loadable(lazy(() => import('../components/query/queryList')));




// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element:  <MainLayout /> ,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
   
    {
      path: 'queries',
      children: [
        {
          path: 'all',
          element: <AllQuery />
        },
        {
          path: 'add',
          element: <AddQuery />
        },
        {
          path: 'my',
          element: <MyEnquiry />
        },
      ]
    },
    {
      path: '/query/:queryId',
      element: <QueryById />
    },
    {
      path: '/queries/:status',
      element: <QueryByStatus/>
    },
    {
      path: '*',
      element: <Navigate to='/' replace />,
    },
  ]
};

export default MainRoutes;
