import dashboard from './dashboard';


import Enquiry from './enquiry'

import Employee from './employee'
import Supplier from './supplier'
import Accounts from './Accounts'
import Website from './website'
import Client from './client'
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard,Enquiry]
};

export default menuItems;






