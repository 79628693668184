
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const enquiryData = createApi({
  reducerPath: 'enquiryData',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL,
    // baseUrl: 'https://holidayhub-crm-api.onrender.com/',
    credentials: 'include',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
   tagTypes: ['Queries','Employee', "Customer", "Supplier", "Follow"], 
  }),

  endpoints: builder => ({

    postQuery: builder.mutation({
      query: body => ({
        url: '/bugTracking/addQuery',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Queries', 'Follow']
    }),

    putQuery: builder.mutation({
      query: body => ({
        url: '/addQuery',
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['Queries']
    }),

    getQuery: builder.query({
      query: () => ({
        url: '/bugTracking/addQuery',
        method: 'GET'
      }),
      providesTags: ['Queries', 'Follow']
    }),

    getQueryByMonth: builder.query({
      query: ({ year, month, noOfQuery, page }) => `/queryByMonth/${year}/${month}/${noOfQuery}/${page}`,
    }),

    postEmployee: builder.mutation({
      query: body => ({
        url: '/employee',
        method: 'POST',
        body: body,

      }),
      invalidatesTags: ['Employee']
    }),

    postFollowups: builder.mutation({
      query: body => ({
        url: '/bugTracking/followUps',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Follow']
    }),

    getFollowups: builder.query({
      query: id => ({
        url: `/bugTracking/followUps/${id}`,
        method: 'GET',
      }),
      providesTags: ['Follow']
    }),

    updateEmployee: builder.mutation({
      query: data => ({
        url: '/employee',
        method: 'PUT',
        body: data,

      }),
      invalidatesTags: ['Employee']
    }),

    deleteEmployee: builder.mutation({
      query: id => ({
        url: '/employee',
        method: 'Delete',
        body: id,

      }),
      invalidatesTags: ['Employee']
    }),

    postLogin: builder.mutation({
      query: body => ({
        url: '/login',
        method: 'POST',
        body: body,
      
      }),
      // invalidatesTags: ['Employee']
    }),


    getLogin: builder.query({
      query: () => ({
        url: '/login',
        method: 'GET'
      }),
     
    }),

    postLogout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'POST'
      }),
     
    }),


    getEmployee: builder.query({
      query: () => ({
        url: '/employee',
        method: 'GET'
      }),
     providesTags:['Employee']
    }),

    postCustomer: builder.mutation({
      query: (body) => ({
        url: '/customer',
        method: 'POST',
        body:body
      }),
     invalidatesTags:["Customer"]
    }),

    postSupplier: builder.mutation({
      query: (body) => ({
        url: '/supplier',
        method: 'POST',
        body:body
      }),
     invalidatesTags:["Supplier"]
    }),


   


    getCustomer: builder.query({
      query: () => ({
        url: '/customer',
        method: 'GET'
      }),
      providesTags:["Customer"]
    }),

    getSupplier: builder.query({
      query: () => ({
        url: '/supplier',
        method: 'GET'
      }),
      providesTags:["Supplier"]
    }),

    putCustomer: builder.mutation({
      query: (body) => ({
        url: '/customer',
        method: 'PUT',
        body:body
      }),
     invalidatesTags:['Customer']
    }),

    putSupplier: builder.mutation({
      query: (body) => ({
        url: '/supplier',
        method: 'PUT',
        body:body
      }),
     invalidatesTags:['Supplier']
    }),

    postHotel: builder.mutation({
      query: (body) => ({
        url: '/addQuery/addHotels',
        method: 'POST',
        body:body
      }),
      invalidatesTags :['Queries']
     
    }),

    postFlight: builder.mutation({
      query: (body) => ({
        url: '/addQuery/addFlight',
        method: 'POST',
        body:body
      }),
      invalidatesTags :['Queries']
     
    }),

    getQueryById:builder.query({
      query: (queryId) => ({
        url: `/query/${queryId}`,
        method: 'GET',
      }),
      providesTags :['Queries']
    }),

    getQueryByUserId:builder.query({
      query: (queryId) => ({
        url: `/queryByUserId/${queryId}`,
        method: 'GET',
      }),
      providesTags :['Queries']
    }),

    getQueryByUserIdFilter:builder.query({
      query: ({ userId, year, month, noOfQuery, page }) => ({
        url: `/queryByUserIdFilter/${userId}/${year}/${month}/${noOfQuery}/${page}`,
        method: 'GET',
      }),
      providesTags :['Queries']
    }),

    getQueryByEnquiryNo:builder.query({
      query: ( enquiryNo ) => ({
        url: `/getQueryByEnquiryNo/${enquiryNo}`,
        method: 'GET',
      }),
      providesTags :['Queries']
    }),

    postOfferBanner:builder.mutation({
      query: (imageFile) => ({
        url: `/offerbanner`,
        method: 'POST',
       body:imageFile
      })
    }),

    postHotelQuotation: builder.mutation({
      query: (body) => ({
        url: '/addQuery/quotation',
        method: 'POST',
        body:body
      }),
      invalidatesTags :['Queries']
    }),
    getTransaction: builder.query({
      query: () => "/transaction",
    }),
    getBilling: builder.query({
      query: () => "/billing",
    }),
    postTransaction: builder.mutation({
      query: (transactionDetails) => ({
        url: '/transaction',
        method: 'POST',
        body:transactionDetails
      }),
     invalidatesTags:["Transaction"]
    }),
    putTransaction: builder.mutation({
      query: (transactionDetails) => ({
        url: '/transaction',
        method: 'PUT',
        body:transactionDetails
      }),
     invalidatesTags:["Transaction"]
    }),
    getLedger: builder.query({
      query: () => "/ledger",
    }),
    postLedger: builder.mutation({
      query: (ledgerDetails) => ({
        url: '/ledger',
        method: 'POST',
        body:ledgerDetails
      }),
     invalidatesTags:["Transaction"]
    }),
    postLedgerId: builder.mutation({
      query: (id) => ({
        url: '/ledgerById',
        method: 'POST',
        body:id
      }),
     invalidatesTags:["Transaction"]
    }),
    getLedgerByIdTransactionType: builder.query({
      query: ({userId, year}) => `/ledgerByIdByTransactionType/${userId}/${year}`,
    }),
    getQueryMonthlyByUserId: builder.query({
      query: ({userId, year}) => `/queryMonthlyByUserId/${userId}/${year}`,
    }),
    postUsernameForOtp: builder.mutation({
      query: (username) => ({
        url: '/forgotPassword',
        method: 'POST',
        body:username
      }),
     invalidatesTags:["ForgetPassword"]
    }),
    putPasswordUpdate: builder.mutation({
      query: (data) => ({
        url: '/forgotPassword',
        method: 'PUT',
        body:data
      }),
     invalidatesTags:['ForgetPassword']
    }),
    getAccountsRule: builder.query({
      query: () => ({
        url: '/accountsRule',
        method: 'GET',
      }),
     providesTags:['accountsRule']
    }),
    PostAccountsRule: builder.mutation({
      query: (data) => ({
        url: '/accountsRule',
        method: 'Post',
        body:data
      }),
     invalidatesTags:['accountsRule']
    }),
    PutAccountsRule: builder.mutation({
      query: (data) => ({
        url: '/accountsRule',
        method: 'PUT',
        body:data
      }),
     invalidatesTags:['accountsRule']
    }),

    getOrganisationById: builder.query({
      query: (id) => ({
        url: `/getOrganisationById/${id}`,
        method: 'GET',
      }),
    //  invalidatesTags:['accountsRule']
    }),
    getOrganisation: builder.query({
      query: () => "/organisation",
    }),
  })
})

export const {

  usePostQueryMutation,
  useGetQueryQuery,
  useGetQueryByMonthQuery,
  useGetQueryByEnquiryNoQuery,
  usePutQueryMutation,
  usePostEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  usePostLoginMutation,
  useGetLoginQuery,
  usePostLogoutMutation,
  useGetEmployeeQuery,
  usePostCustomerMutation,
  useGetCustomerQuery,
  usePutCustomerMutation,
  usePostHotelMutation,
  usePostFlightMutation,
  useGetSupplierQuery,
  usePostSupplierMutation,
  usePutSupplierMutation,
  useGetQueryByIdQuery,
  useGetQueryByUserIdQuery,
  useGetQueryByUserIdFilterQuery,
  usePostOfferBannerMutation,
  usePostHotelQuotationMutation,
  useGetBillingQuery,
  useGetTransactionQuery,
  usePostTransactionMutation,
  usePutTransactionMutation,
  useGetLedgerQuery,
  usePostLedgerMutation,
  usePostLedgerIdMutation,
  usePostUsernameForOtpMutation,
  usePutPasswordUpdateMutation,
  useGetLedgerByIdTransactionTypeQuery,
  useGetQueryMonthlyByUserIdQuery,
  useGetAccountsRuleQuery,
  usePostAccountsRuleMutation,
  usePutAccountsRuleMutation,
  useGetOrganisationByIdQuery,
  useGetOrganisationQuery,


  usePostFollowupsMutation,
  useGetFollowupsQuery
} = enquiryData;
