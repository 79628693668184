export default function GreetingMessage() {
  const greet = [
    "Good Night",
    "Good Morning",
    "Good Afternoon",
    "Good Evening",
  ][parseInt((new Date().getHours() / 24) * 4, 10)];

  return <>{greet}</>;
}
