import { useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

const token = localStorage.getItem('token');

export default function ThemeRoutes() {

  const routes = useRoutes([token ? MainRoutes : AuthenticationRoutes ]);

  return routes;
}
