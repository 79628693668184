import * as React from 'react'
import QueryFilter from '../QueryFilter'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import style from './style.module.css'
import EditCustomer from './customerDetails'
import Button from '@mui/material/Button'
import { BiSolidDownArrow } from 'react-icons/bi'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useNavigate } from 'react-router-dom'
import TableNavigation from '../TableNavigation'
import { MdDelete } from 'react-icons/md'
import { TbEdit } from 'react-icons/tb'
import { useGetQueryByEnquiryNoQuery, useGetQueryQuery } from 'store/crm'
import moment from 'moment/moment'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#64ccc5',
    color: theme.palette.common.white,
    fontFamily: 'souge',
    fontWeight: '500',
    border: '1px solid gray'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13.5,
    fontFamily: 'comfortaa',
    border: '1px solid gray'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    border: '1px solid gray'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: '1px solid gray'
  }
}))

function TableSheet () {
  const { data } = useGetQueryQuery()
  console.log(data?.data)

  return (
    <>
      {data && (
        <>
          <div className={style.btnDiv}></div>
          <Card
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pt: 0.3
            }}
            elevation={0}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <Typography sx={{ fontFamily: 'souge', fontSize: '18px' }}>
                {/* <>{result.length}</> */}
              </Typography>
            </Typography>
          </Card>

          <div></div>

          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 700, maxHeight: '100vh' }}
              aria-label='customized table'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Issue No.</StyledTableCell>
                  <StyledTableCell>Raised By</StyledTableCell>
                  <StyledTableCell>Department</StyledTableCell>

                  <StyledTableCell>Issue Title</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Assign To</StyledTableCell>
                  <StyledTableCell>CreatedAt</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.map(item => {
                  return (
                    <>
                      <StyledTableRow>
                        <StyledTableCell>{item.enquiryNo}</StyledTableCell>
                        <StyledTableCell>{item.handledBy}</StyledTableCell>
                        <StyledTableCell>{item.employId?.department}</StyledTableCell>
                        <StyledTableCell>{item.issueTitle}</StyledTableCell>
                        <StyledTableCell>{item.status}</StyledTableCell>
                        <StyledTableCell>{item.assignTo?.split("-")[0]}</StyledTableCell>
                        <StyledTableCell>{moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</StyledTableCell>
                        <StyledTableCell>
                          <EditCustomer client={item} />
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  )
                })}
           
        

      
              </TableBody>
            </Table>
          </TableContainer>
          <TableNavigation />
        </>
      )}
    </>
  )
}

const customButton = (text, color) => {
  return (
    <Button
      variant='contained'
      color={color}
      sx={{ height: '25px', textTransform: 'capitalize' }}
    >
      {text == 'confirm' ? <>Confirmed</> : <>{text}</>}
      {/* {text} */}
    </Button>
  )
}

export default TableSheet
