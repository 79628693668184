import { lazy } from 'react';
import {Navigate} from 'react-router-dom'

// project imports
import Loadable from 'components/ui-component/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('../pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('../pages/authentication/authentication3/Register3')));
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/Default')));
const ForgetPassword = Loadable(lazy(() => import('../pages/authentication/authentication3/ForgetPassword')));

const token = localStorage.getItem('token');

// ==============================|| AUTHENTICATION ROUTING ||============================== //
const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/pages/login/login3',
      element: !token ? <AuthLogin3 /> : <Navigate to='/dashboard/default' replace />
    },
    {
      path: '/pages/register/register3',
      element: !token ?  <AuthRegister3 /> : <Navigate to='/dashboard/default' replace />
    },
    {
      path: '/pages/forget/password',
      element: !token ? <ForgetPassword /> : <Navigate to='/dashboard/default' replace />
    },
    {
      path: '/',
      element: token ? <DashboardDefault /> : <Navigate to='/pages/login/login3' replace />
    },
    {
      path: '*',
      element: !token? <Navigate to='/pages/login/login3' replace />: null ,
    },
  ]
};

export default AuthenticationRoutes;


