import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from '@mui/lab/TimelineOppositeContent'
import { useGetFollowupsQuery } from 'store/crm'
import moment from 'moment'

export default function LeftAlignedTimeline ({ issueId }) {
  let { data } = useGetFollowupsQuery(issueId)
  console.log(data)
  return (
    <Timeline
    >
      {data &&
        data?.data?.map(follow => {
          return (
            <>
              <TimelineItem>
                <TimelineOppositeContent color='textSecondary'>
                  {moment(follow.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>{follow.status}</TimelineContent>
              </TimelineItem>
            </>
          )
        })}
    </Timeline>
  )
}
