import React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import styles from "./style.module.css";
export default function TableNavigation({ data, handleChange }) {
  const handleFirstPage = () => {
    if (data.currentPage === 1) return;
    handleChange("1");
  };

  const handleLastPage = () => {
    if (data.noOfPages && data.noOfPages > 1) {
      handleChange(String(data.noOfPages));
    }
  };

  const handlePrevPage = () => {
    if (data.currentPage && data.currentPage > 1) {
      const newPage = Number(data?.currentPage) - 1;
      handleChange(String(newPage));
      console.log(newPage);
    }
  };

  const handleNextPage = () => {
    if (data.currentPage && data.currentPage < data.noOfPages) {
      const newPage = Number(data?.currentPage) + 1;
      handleChange(String(newPage));
    }
  };

  return (
    <div className={styles.wrapper}>
      <span>
        Showing <span className={styles.fontDarker}>{data?.data?.length}</span>
        {" entries "}
        of page <span className={styles.fontDarker}>{data?.currentPage}</span>
        {" out of "}
        <span className={styles.fontDarker}>{data?.noOfPages}</span> pages
      </span>
      <br />
      <ButtonGroup
        disableElevation
        variant="outlined"
        aria-label="Disabled elevation buttons"
      >
        <Button onClick={handleFirstPage}>First</Button>
        <Button onClick={handlePrevPage}>Prev</Button>
        <Button onClick={handleNextPage}>Next</Button>
        <Button onClick={handleLastPage}>Last</Button>
      </ButtonGroup>
    </div>
  );
}
