import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import DropDownMenu from "../DropDownMenu";
import {
  useGetQueryByMonthQuery,
  useGetQueryByUserIdFilterQuery,
} from "../../../store/crm";
import { useLocation } from "react-router-dom";

const months = [
  { name: "Jan", value: "01" },
  { name: "Feb", value: "02" },
  { name: "Mar", value: "03" },
  { name: "Apr", value: "04" },
  { name: "May", value: "05" },
  { name: "Jun", value: "06" },
  { name: "Jul", value: "07" },
  { name: "Aug", value: "08" },
  { name: "Sep", value: "09" },
  { name: "Oct", value: "10" },
  { name: "Nov", value: "11" },
  { name: "Dec", value: "12" },
  { name: "All", value: "13" },
];

const years = [
  { name: "2024", value: "2024" },
  { name: "2023", value: "2023" },
];

const noOfQueries = [
  { name: "25", value: "25" },
  { name: "50", value: "50" },
  { name: "100", value: "100" },
  { name: "500", value: "500" },
  { name: "1000", value: "1000" }
];

const initialYear = new Date().getFullYear();

const userId = localStorage.getItem("id");

export default function QueryFilter({ handleTableData, pageValue = "1" }) {
  const [year, setYear] = useState(initialYear);
  const [month, setMonth] = useState("13");
  const [noOfQuery, setNoOfQuery] = useState("50");
  const [page, setPage] = useState("1");
  const { pathname } = useLocation();

  let handleFetchApiData;

  switch (pathname) {
    case "/queries/my":
      handleFetchApiData = useGetQueryByUserIdFilterQuery;
      break;

    case "/queries/all":
      handleFetchApiData = useGetQueryByMonthQuery;
      break;

    default:
      handleFetchApiData = useGetQueryByMonthQuery;
      break;
  }

  const { data } = handleFetchApiData({
    userId,
    year,
    month,
    noOfQuery,
    page,
  });

  useEffect(() => {
    handleTableData(data);
  }, [handleTableData, data]);

  useEffect(() => {
    setPage(pageValue);
  }, [pageValue]);

  const handleMonthChange = (val) => {
    setMonth(val);
  };

  const handleYearChange = (val) => {
    setYear(val);
  };

  const handleQueryChange = (val) => {
    setNoOfQuery(val);
  };

  return (
    <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
      <DropDownMenu
        options={months}
        handleChange={handleMonthChange}
        name="Month"
        initialValue={month}
      />

      <DropDownMenu
        options={years}
        handleChange={handleYearChange}
        name="Year"
        initialValue={year}
      />

      <DropDownMenu
        options={noOfQueries}
        handleChange={handleQueryChange}
        name="Query"
        initialValue={noOfQuery}
      />
    </Box>
  );
}
