import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { enquiryData } from './crm';
import customizationReducer from './customizationReducer';

// Combine reducers
const rootReducer = combineReducers({
  [enquiryData.reducerPath]: enquiryData.reducer,
  customization: customizationReducer,
  // Add more reducers as needed
});

// Configure store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(enquiryData.middleware),
  // Add more configurations as needed
});

export default store;


